import LogRocket from "logrocket";
import { useEffect } from "react";

const Home = () => {

  useEffect(() => {
    LogRocket.debug('navigate to home')
  }, [])

  return (
    <div>Home</div>
  );
}

export default Home;
