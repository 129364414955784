import React from 'react';
import ReactDOM from 'react-dom/client';
import { createBrowserRouter,  RouterProvider } from "react-router-dom";
import LogRocket from 'logrocket';
import setupLogRocketReact from 'logrocket-react';
import './index.css';
import App from './App';
import Home from './Home';
import Test from './Test';
import ErrorPage from './Error';

LogRocket.init('ppaxxu/kiosk-test', {
  release: '0.1.0',
});
setupLogRocketReact(LogRocket);

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/home",
    element: <Home />,
  },
  {
    path: "/test",
    element: <Test />,
  }
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);
