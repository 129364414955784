import { useEffect } from "react";
import { Link, useSearchParams } from "react-router-dom";
import LogRocket from 'logrocket';
import axios from "axios";

const App = () => {
  const [searchParams] = useSearchParams();

  const identify = (serial) => {
    const logdata = {
      name: serial,
      email: 'Restaurant Default',
      client: 'Restaurant Default',
      environment: 'uat',
      organisation: 'TYIMXJyrsby3hAf4Z1Wk',
      storeId: 'fm-generic',
      serial
    }
    LogRocket.warn(logdata);
    LogRocket.identify(serial, logdata);
  }

  const verify = async (serial) => {
    var recordingURL;
    LogRocket.getSessionURL(function(sessionURL) {
      recordingURL = sessionURL;
    });
    const config = {
      headers:{
        'X-LogRocket-URL': recordingURL,
      }
    };
    const res = await axios.get(`https://us-central1-kiosklogstest.cloudfunctions.net/serialLogRocket?serial=${serial}`, config)
    LogRocket.info(JSON.stringify(res));
  }

  useEffect(() => {
    const serial = searchParams.get('serial');
    if (serial) {
      identify(serial)
      verify(serial);
    }
  }, [searchParams])

  return (
    <>
      <h1>{`Serial: ${searchParams.get('serial')}`}</h1>
      <div>
        <ul>
          <li><Link to={`home`}>Home Page</Link></li>
          <li><Link to={`test`}>Test Page</Link></li>
        </ul>
      </div>
    </>
  );
}

export default App;
