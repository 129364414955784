import LogRocket from "logrocket";
import { useEffect } from "react";

const Test = () => {

  useEffect(() => {
    LogRocket.debug('navigate to test');
  }, [])

  return (
    <div>Test</div>
  );
}

export default Test;
