import LogRocket from "logrocket";
import { useEffect } from "react";
import { useRouteError } from "react-router-dom";

export default function ErrorPage() {
  const error = useRouteError();

  useEffect(() => {
    if (error) {
      LogRocket.error('error page', error.statusText || error.message);
      LogRocket.captureMessage('Something is wrong!', {
        tags: {
          client: 'Restaurant Default',
          environment: 'uat',
          organisation: 'TYIMXJyrsby3hAf4Z1Wk',
          storeId: 'fm-generic',
        },
        extra: {
          pageName: 'ErrorPage',
          error: error.statusText || error.message
        },
      });
    }
  }, [error]);

  return (
    <div id="error-page">
      <h1>Oops!</h1>
      <p>Sorry, an unexpected error has occurred.</p>
      <p>
        <i>{error.statusText || error.message}</i>
      </p>
    </div>
  );
}